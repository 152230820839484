<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 mt-5 text-center">
                    <h4>{{$t('dashboard.titre')}} {{config.etablissement.display_name}}</h4>
                    <h5 class="text-primary">{{$t('dashboard.bienvenue')}} {{getUser.full_name}}</h5>
                </div>
            </div>
            <div v-if="doctors && doctors.length > 1" class="row">
                <div v-for="doctor in doctors" class="col-lg-3 mt-5 d-flex">
                    <div class="card bg-light flex-grow-1">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h5 class="">{{doctor.full_name}}</h5>
                                <h5><i class="fas fa-user-md"></i></h5>
                            </div>
                            <div v-for="day in weekdays.getWorkingDays()">
                                <div v-if="doctor.horaire_info[day.id] >= 10" class="alert alert-warning">
                                    <i class="bi bi-exclamation-triangle-fill"></i> Attention, {{doctor.full_name}} a {{doctor.horaire_info[day.id]}} horaires différents le {{day.label}},
                                    de trop nombreux horaires peuvent ralentir l'application.
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <router-link :to="'/admin/horaires/'+doctor.id" class="btn btn-primary btn-sm mt-5 mr-2">{{$t('dashboard.voir-horaires')}}</router-link>
                                <router-link :to="'/admin/calendrier/'+doctor.id" class="btn btn-primary btn-sm mt-5">{{$t('dashboard.voir-calendrier')}}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "../../store/namespaces";
    import EWeekDays from "../../enums/EWeekDays";
    export default {
        name: "Dashboard",
        data() {
            return {
                loading: false,
                config: Vue.$config,
                doctors: null,
                weekdays: EWeekDays
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),
        },
        async mounted() {
            let res  = await axios.get('/dashboard?app_id='+this.config.app_id, {headers: {
                    "content-type": "application/json",
                    "Accept": "application/json, text/plain",
                    "Authorization": "Bearer " + this.getUser.api_token
                }})
            this.doctors = res.data.doctors
        }
    }
</script>

<style scoped>

</style>
